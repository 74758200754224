import jQuery from 'jquery/dist/jquery';
import DataTable from 'datatables.net-dt';
import 'datatables.net-bs5'
import 'datatables.net-select-dt'
import 'datatables.net-buttons-dt'

import * as bootstrap from 'bootstrap'
import Swal from 'sweetalert2';

import Utils from '../../../../utils'
import DefaultController from "../../../defaultController";
import ErpProductEntity from '../entity';

export default class ErpProductList extends DefaultController {
    tableElem: any;
    datatable: any;

    async init() {
        this.entity = "erp/products"
        this.tableElem = jQuery('.datatables-users')
        this.createTable();
        await super.init();
    }

    getEntityData(elem: any) {
        return ErpProductEntity.getEntityData(elem)
    }

    bindListeners() {
        this.tableElem.delegate(".delete-record", "click", async (e: any) => {const confirm = await Swal.fire({
            title: 'Are you sure?',
            text: "You won't be able to revert this!",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: 'Yes, delete it!',
            customClass: {
                confirmButton: 'btn btn-primary me-3',
                cancelButton: 'btn btn-label-secondary'
            },
            buttonsStyling: false
        })
            if(confirm.value === true) {
                const id = e.currentTarget.getAttribute("data-id");
                const r = await Utils.entity.destroy(id, this.entity);
                if (r.status === 200) {
                    this.toastr.success(`${Utils.translate('erp.product.name')} ${Utils.translate('generic.deleted')}`, `${Utils.translate('generic.success')}`)
                } else {
                    this.toastr.error(`${Utils.translate('erp.product.name')} ${Utils.translate('generic.messages.not_deleted')}`, `${Utils.translate('generic.error')}`)
                }
                this.datatable.ajax.reload();
            }
        });
        jQuery("body").delegate(".new_product", "click", (e: any) => {
            const href = e.currentTarget.getAttribute("href");
            document.location.href = href;
        })
    }

    createTable() {
        this.datatable = new DataTable('.datatables-users', {
            initComplete: () => {
                this.tableElem.closest(".card").find(".loading-body").addClass("d-none")
                this.tableElem.closest(".card").find(".card-datatable").removeClass("d-none")
            },
            layout: {
                topEnd: ['search', 'buttons']
            },
            pageLength: 25,
            serverSide: true,
            ajax: {
                url: "/api/v1/erp/products",
                dataType: 'json',
                // @ts-ignore
                data: (params: { length: any; start: any; draw: any; order: any; search: any; }) => {
                    console.log("aaa", params)
                    return {
                        limit: params.length,
                        page: params.start/25+1,
                        draw: params.draw,
                        search: params.search ? params.search.value : null,
                        sort: params.order ? params.order[0] : []
                    };
                },
                // @ts-ignore
                dataSrc: (data: any, params: any) => {
                    if(data.status === 200) {
                        data["recordsTotal"] = data.meta.total;
                        data["recordsFiltered"] = data.meta.total;
                        data["draw"] = data.meta.draw;
                    }
                    return data.data;
                },
                cache: true
            },
            select: true,
            columns: [
                {data: 'productNumber'},
                {data: 'name'},
                {data: 'stock'},
                {data: 'manufacturer'},
                {data: 'uuid'}
            ],
            columnDefs: [
                {
                    targets: 0,
                    render: (data: any, type: any, full: any, meta: any) => {
                        return `<a href="/${(<any>window).currentLocale}/erp/products/${full.id}">${data}</a>`;
                    },
                },
                {
                    targets: 1,
                    render: (data: any, type: any, full: any, meta: any) => {
                        return data;
                    },
                },
                {
                    targets: 2,
                    render: (data: any, type: any, full: any, meta: any) => {
                        return data;
                    },
                },
                {
                    targets: 3,
                    render: (data: any, type: any, full: any, meta: any) => {
                        return  data[0] ? data[0].name : "";
                    },
                },
                {
                    targets: 4,
                    searchable: false,
                    orderable: false,
                    render: (data: any, type: any, full: any, meta: any) => {
                        return `<div class="d-flex align-items-center justify-content-end">
                                  <a href="/${(<any>window).currentLocale}/erp/products/${data}" class="text-body">
                                    <i class="ti ti-edit ti-sm me-2"></i>
                                  </a>
                                  <a href="#" data-id="${data}" class="text-body delete-record">
                                    <i class="ti ti-trash ti-sm mx-2"></i>
                                  </a>
                                </div>`
                    },
                }
            ],
            processing: true,
            language: {
                sLengthMenu: '_MENU_',
                search: '',
                searchPlaceholder: `${Utils.translate('generic.search')}...`,
                "zeroRecords":    `${Utils.translate('generic.datatable.no_results')}`,
                "emptyTable":     `${Utils.translate('generic.datatable.no_results')}`,
                "paginate": {
                    "first":      `${Utils.translate('generic.datatable.pagination.first')}`,
                    "last":       `${Utils.translate('generic.datatable.pagination.last')}`,
                    "next":       `${Utils.translate('generic.datatable.pagination.next')}`,
                    "previous":   `${Utils.translate('generic.datatable.pagination.previous')}`
                },
                "info":           `${Utils.translate('generic.datatable.info.info')}`,
                "infoEmpty":      `${Utils.translate('generic.datatable.info.empty')}`,
                "infoFiltered":   `${Utils.translate('generic.datatable.info.filtered')}`,
            },
            buttons: [
                {
                    text: `<i class="ti ti-plus me-0 me-sm-1 ti-xs"></i><span class="d-none d-sm-inline-block">${Utils.translate('generic.add')}</span>`,
                    className: 'dt-button add-new btn btn-primary m-2 new_product',
                    attr: {
                        'href': `/${(<any>window).currentLocale}/erp/products/new`
                    }
                }
            ]
        });
    }
}